import { API } from './API';
import axios from 'axios';


// ALL REVIEWS THIS CLINIC

export async function LoadReviews(page, pageNumber) {

    // USE FETCH
    // let res = await fetch(API.REVIEWS.GET, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.REVIEWS.GET}?p=${pageNumber}`, { timeout: 5000 });
    return response.data;
}


export async function CheckIfUserAddLikeThisReview(id, publishByLike) {

    const response = await axios.get(`${API.REVIEWS.GET}/showReviewCheckUserLike/${id}/${publishByLike}`);
    
    if (response.data) {
        sessionStorage.setItem("likeReview", true);
    }
    else {
        sessionStorage.setItem("likeReview", false);
    }
}



// ADOUT THIS CLINIC COUNT DETAILS

export async function LoadCountDoctors() {

    // USE FETCH
    // let res = await fetch(`${API.USERS.GET}/countDoctors`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.USERS.GET}/countDoctors`, { timeout: 5000 });
    return response.data;
}


export async function LoadCountUsers() {

    // USE FETCH
    // let res = await fetch(`${API.USERS.GET}/countUsers`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.USERS.GET}/countUsers`, { timeout: 5000 });
    return response.data;
}


export async function LoadCountReviews() {

    // USE FETCH
    // let res = await fetch(`${API.REVIEWS.GET}/countReviews`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.REVIEWS.GET}/countReviews`, { timeout: 5000 });
    return response.data;
}



// ADMIN WORK

export async function LoadAllUsers() {

    // USE FETCH
    // let res = await fetch(`${API.USERS.GET}`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(API.USERS.GET, { timeout: 5000 });
    return response.data;
}

export async function LoadAllUsersTable() {

    // USE FETCH
    let res = await fetch(`${API.USERS.GET}`, { method: 'GET' });
    let data = await res.json();
    console.log ("loadtableaxiosuser: "+data);
    return data;

    // USE AXIOS
    // const response = await axios.get(API.USERS.GET, { timeout: 5000 });
    // return response.data;
}

export async function LoadAllUsersBlocked() {

    // USE FETCH
    // let res = await fetch(`${API.USERS.GET}/BlockUsers`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.USERS.GET}/BlockUsers`, { timeout: 5000 });
    return response.data;
}


export async function LoadAllDoctors() {

    // USE FETCH
    // let res = await fetch(`${API.USERS.GET}/showDoctors`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.USERS.GET}/showDoctors`, { timeout: 5000 });
    return response.data;
}


export async function LoadAllReviews() {

    // USE FETCH
    // let res = await fetch(API.REVIEWS.GET, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(API.REVIEWS.GET, { timeout: 5000 });
    return response.data;
}




// USER DATA

export async function LoadMedicalFileUser(code) {

    // USE FETCH
    // let res = await fetch(`${API.MEDICAL_FILE.GET}/${code}`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.MEDICAL_FILE.GET}/${code}`, { timeout: 5000 });
    return response.data;
}


export async function showAllMyReview(code) {

    // USE FETCH
    // let res = await fetch(`${API.REVIEWS.GET}/${code}`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.REVIEWS.GET}/${code}`, { timeout: 5000 });
    return response.data;
}


export async function LoadMedicalFileUserIsNotActive(code) {

    // USE FETCH
    // let res = await fetch(`${API.MEDICAL_FILE.GET}/showHistoryFiles/${code}`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.MEDICAL_FILE.GET}/showHistoryFiles/${code}`, { timeout: 5000 });
    return response.data;
}



// DOCTOR WORK

export async function LoadUsersActive_queues() {

    // USE FETCH
    // let res = await fetch(`${API.USERS.GET}/showTurnUsers`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.USERS.GET}/showTurnUsers`, { timeout: 5000 });
    return response.data;
}


export async function LoadMedicalFileAllUsersHowNeedPay() {

    // USE FETCH
    // let res = await fetch(`${API.MEDICAL_FILE.GET}/showHowNeedPay`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.MEDICAL_FILE.GET}/showHowNeedPay`, { timeout: 5000 });
    return response.data;
}



// APPOINTMENT

export async function LoadDays() {

    // USE FETCH
    // let res = await fetch(API.DAYS.GET, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(API.DAYS.GET, { timeout: 5000 });
    return response.data;
}


export async function LoadHour(Serial_code) {

    // USE FETCH
    // let res = await fetch(`${API.HOURS.GET}/${Serial_code}`, { method: 'GET' });
    // let data = await res.json();

    Serial_code = JSON.parse(sessionStorage.getItem("day"));
    // USE AXIOS
    let response = await axios.get(`${API.HOURS.GET}/${Serial_code.Serial_code}`, { timeout: 5000 });
    return response.data;
}

///////////////////////////////////////////


export async function LoadAllShippings() {

    // USE FETCH
    // let res = await fetch(`${API.SHIPPINGS.GET}`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(API.SHIPPINGS.GET, { timeout: 5000 });
    return response.data;
}

export async function LoadAllShippingsTable() {

    // USE FETCH
    let res = await fetch(`${API.SHIPPINGS.GET}`, { method: 'GET' });
    let data = await res.json();
    console.log ("loadtableaxios: "+data);
    return data;

    // USE AXIOS
    // const response = await axios.get(API.SHIPPINGS.GET, { timeout: 5000 });
    // console.log ("loadtableaxios: "+JSON.stringify(response.data));
    // return JSON.stringify(response.data);
}

export async function getMaxNumShipping() {

    // USE FETCH
    // let res = await fetch(`${API.SHIPPINGS.GET}/maxNumShippings`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const headers = {
        'Content-Type': 'application/json'
    }
    let maxNumShipping = 0;
    const response = await axios.get(`${API.SHIPPINGS.GET}/maxNumShippings`, { headers: headers });
    maxNumShipping = response.data.MaxNumShipping != null ? response.data.MaxNumShipping : 1 
    console.log("maxNumShippingslet:"+maxNumShipping);
    console.log("maxNumShippings:"+JSON.stringify(response.data));
    sessionStorage.setItem("maxNumShipping",JSON.stringify(response.data));
    // return response.data;
}

export async function LoadAllShippingsBlocked() {

    // USE FETCH
    // let res = await fetch(`${API.SHIPPINGS.GET}/BlockShippings`, { method: 'GET' });
    // let data = await res.json();
    // return data;

    // USE AXIOS
    const response = await axios.get(`${API.SHIPPINGS.GET}/BlockShippings`, { timeout: 5000 });
    return response.data;
}
