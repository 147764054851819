import React from 'react'
import date1 from '../Json_date/date.json'
import '../css/Servicios.css'
import { Card, Row, Col } from 'react-bootstrap'
import { motion as m } from "framer-motion/dist/framer-motion"



//show data from json file our work this clinic
function Servicios() {


    let storedTheme = localStorage.getItem("theme");



    return (

        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            // initial={{ y:"100%" }}
            // animate={{ y:"0%" }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
        >
            <div className='styleServiciossModel'>

                <div className={(storedTheme === "light") ? "titleServiciosDark" : (storedTheme === "dark") ? "titleServicios" : ""}>
                    <br />
                    <h1>Servicios que realizamos</h1>
                    <p>* Si deseas más información<br /> <a href='/Contactenos'>Click y ve a nuestra página de contacto</a></p>
                </div>


                <div className='ServiciosLook'>
                    <Row xs={1} md={2} lg={3} style={{ width: "100%" }}>
                        {date1.work.map((record) => (

                            <Col key={record.id}>

                                <Card
                                    style={(storedTheme === "light") ?
                                        { marginTop: "10px", background: "#424242", borderBottomRightRadius: "25px", borderBottomLeftRadius: "25px", marginTop: "12%" } :
                                        (storedTheme === "dark") ?
                                            { borderBottomRightRadius: "25px", borderBottomLeftRadius: "25px", marginTop: "12%" } : ""}>

                                    <Card.Img variant="top" src={record.path} />

                                    <Card.Body
                                        style={(storedTheme === "light") ?
                                            { color: "#ffffffab" } :
                                            (storedTheme === "dark") ?
                                                {} : ""}>

                                        <Card.Title><h3>{record.title}</h3></Card.Title>

                                        <Card.Text>
                                            {record.text}
                                        </Card.Text>
                                    </Card.Body>

                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>

        </m.div>
    )

}

export default Servicios;