import React from 'react'
import Admin from '../components/Admin'
import Doctor from '../components/Doctor'
import User from '../components/User'

import Swal from 'sweetalert2'

import NotFoundPage from '../components/NotFoundPage'
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


// profile page , open profile page as per UserType_code from data base , user-admin-doctor
function Profile() {


    let userData = JSON.parse(sessionStorage.getItem("user"));
    let storedTheme = localStorage.getItem("theme");
    
    if (userData != null) {
        //save in object all data user from session storage "user"
        const obj = {
            code: userData._id,
            name: userData.FirstName,
            login: userData.User_Login,
            email: userData.Email,
            birthday: userData.Birthday,
            password: userData.User_password,
            confirm_password: userData.ConfirmPassword,
            day: userData.Creation_date,
            hour: userData.Update_date,
            codeHour: userData.Serial_codeHour
        }

        //bodega page
        if (userData.UserType_code == 1) {
            return (
                <User data_user={obj} />
            )
        }

        //admin page
        if (userData.UserType_code == 3) {

            return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Admin />
            </LocalizationProvider>
                
            )
        }
    }
    else {

        Swal.fire({
            icon: 'warning',
            title: 'Login / Registro',
            html: 'Necesitas iniciar sesión o registrarte',
            toast: true,
            position: 'top-end',
            confirmButtonColor: "green",
            background: `${(storedTheme === "light") ? "#373E44" :
                (storedTheme === "dark") ? "" : ""}`,
            color: `${(storedTheme === "light") ? "#ffffffab" :
                (storedTheme === "dark") ? "" : ""}`,
            buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                (storedTheme === "dark") ? "" : ""}`
        })
        return (
            <NotFoundPage />
        )

    }
}

export default Profile;