import { Button, Modal, Form } from 'react-bootstrap'
import '../css/home.css'
import React, { useState } from 'react'
// import videoBg from '../images/FilmForth Untitled.mp4'
import Category from '../components/category'
import Appointment from '../components/Appointment'
import Swal from 'sweetalert2'
import CloseIcon from '@mui/icons-material/Close';
import { motion as m } from "framer-motion/dist/framer-motion"
import { container, item } from "../styleComponents/StyleAnimation"



// Home page
function Home() {


    let storedTheme = localStorage.getItem("theme");

    // show popup add , Appointment
    const [showModelAppointment, setShowModelAppointment] = useState(false);
    const handleCloseModelAppointment = () => setShowModelAppointment(false);
    const handleShowModelAppointment = () => setShowModelAppointment(true);

    let userData = JSON.parse(sessionStorage.getItem("user"));



    const CheckUserConnected = () => {

        if (userData != null && userData.Creation_date === null && userData.UserType_code === 1) {
            // show popup,Appointment
            handleShowModelAppointment();
        }

        else {

            Swal.fire({
                icon: 'warning',
                html: `${(userData === null) ? 'Necesitas iniciar sesión / Registro' :
                    (userData.Creation_date != null) ? 'You have an Appointment, Cancel it and book a new Appointment' :
                        (userData.UserType_code === 2) ? 'you are Doctor (You can not book an appointment) !' :
                            (userData.UserType_code === 3) ? 'you are Admin (You can not book an appointment) !' : ""}`,
                toast: true,
                position: 'top-end',
                confirmButtonColor: "green",
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })
            return;
        }
    }



    const closePopUpAppintment = () => {

        sessionStorage.removeItem("day");
       window.location.reload(false);
    }



    return (

        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            // initial={{ y: "100%" }}
            // animate={{ y: "0%" }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
        >

            <div className='main1'>
                {/* <video src={videoBg} loop autoPlay muted playsInline /> */}
                {/* <link src='https://i.postimg.cc/KzGWXd0Z/5901203-2009.jpg' /> */}
                <img src="/logo-inicio.png" alt="image" width="500" height="600"/>
                {/* <m.div variants={container} initial="hidden" animate="show" className="content">
                    <m.h1 variants={item}>Bienvenidos</m.h1>
                    <m.p variants={item}>Grupo TREPILASTRI</m.p>
                    <br />
                </m.div> */}
            </div>





            {/* show all Category from json file
            < Category /> */}

        </m.div >
    )

}

export default Home;