import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Tabs, Tab, Button, Row, Form, Col, Table } from 'react-bootstrap';
import '../css/profile.css'
import Swal from 'sweetalert2'
import { LoadAllUsers, LoadAllUsersTable, LoadAllUsersBlocked, LoadAllDoctors, LoadAllReviews } from '../Api/LoadDataFromApi'
import { DeleteUser, UpdateDataUser, DeleteReview, ActiveUserInDataBase } from '../Api/DeleteUpdateDataFromApi'
import { AddNewUserRegester } from '../Api/ConnectOrAddFromApi'
import DeleteIcon from '@mui/icons-material/Delete';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import { MaterialReactTable } from 'material-react-table';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';


//Date Picker Imports
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import NotFoundPage from '../components/NotFoundPage'

export const roles = [
    {
        id: '1',
        role: 'None',
    },
    {
        id: '2',
        role: 'Preparando',
    },
    {
        id: '3',
        role: 'Enviado',
    },
    {
        id: '4',
        role: 'Entregado',
    },
];
export const estadoActivo = [
    {
        id: '1',
        value: 'Activo',
    },
    {
        id: '2',
        value: 'Eliminado',
    },
];

export const usuarioRole = [
    {
        id: '1',
        value: 'Bodega',
    },
    {
        id: '3',
        value: 'Admin',
    },
];
//here component Admin we to do what admin can do = this component use in profile
function Admin() {


    const [Users, SetUsers] = useState([])
    const [UsersBlocked, SetUsersBlocked] = useState([])
    const [Doctors, SetDoctors] = useState([])
    const [Reviews, SetReviews] = useState([])
    // let d = new Date();
    // let DatePublished = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    let date = moment(new Date()).format('DD/MM/YYYY')
    const [Birthday, setBirthday] = useState(date);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});

    let CountUser = 1;
    let CountUserBlock = 1;

    const [Login, setLogin] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');


    let storedTheme = localStorage.getItem("theme");
    let userData = JSON.parse(sessionStorage.getItem("user"));

    //check all input if all good Add to new doctor(AddDoctor)
    const [validated, setValidated] = useState(false);


    const handleCreateNewRow = (values) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    ///
    const checkInputValueUpdateUser = async (values) => {

        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (values.User_password != values.ConfirmPassword ||
            values.User_password.length < 6 && values.ConfirmPassword.length <= 6 ||
            values.User_Login === '' ||
            values.FirstName === '' ||
            values.Email === '' ||
            values.User_password === '' ||
            values.ConfirmPassword === '' ||
            mailFormat.test(values.Email) === false) {

            Swal.fire({
                icon: 'error',
                title: 'Porfavor revisar',
                toast: true,
                confirmButtonColor: "green",
                html: `(1) Ingrese todos los valores(Entrada Incorrecta) ! <br/>
             (2) Contraseña no coincide ! <br/>
             (3) Contraseña con 6 más digitos o letras ! <br/>
             (4) Revise si su contraseña es correcta`,
                position: 'top-end',
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })
            return;
        }


        else {

            await updateUser(values);
        }

    }



    const updateUser = async (values) => {


        let user = {
            FirstName: values.FirstName,
            User_Login: values.User_Login,
            Birthday: values.Birthday,
            Email: values.Email,
            User_password: values.User_password,
            UserType_code: values.UserType_code,
            ConfirmPassword: values.ConfirmPassword,
            Creation_date: values.Creation_date,
            Update_date: values.Update_date,
            Serial_codeHour: null,
            IsActive: values.IsActive
        };
        await Swal.fire({
            position: "center",
            background: "none",
            showConfirmButton: true,
            timer: 4000,
            allowOutsideClick: false,
            html: '<div class="ShowImageWhenRegister"><img src="https://i.postimg.cc/MZP7Xzk6/cute-penguin.gif">' + JSON.stringify(values) + ' </div>',
        });
        console.log("updatefromadmin:"+values._id)
        await UpdateDataUser(values._id, user);
    }

    ///
    const BirthdayChange = (e) => {
        setBirthday(e.target.value);        
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            values.Update_date = moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
            tableData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            setTableData([...tableData]);
            // Swal.fire({
            //     icon: 'warning',
            //     title: 'edit' ,
            //     html: JSON.stringify(values),
            //     toast: true,
            //     position: 'top-end',
            //     confirmButtonColor: "green",
            //     buttonColor: "#E96E00"
            // })
            await checkInputValueUpdateUser(values);
            await exitEditingMode(); //required to exit editing mode and close modal
            // window.location.reload(false);
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                // eslint-disable-next-line no-restricted-globals
                !confirm(`Seguro que deseas eliminar ${row.getValue('FirstName')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            DeleteUser(row.getValue('_id'));

            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            window.location.reload(false);


        },
        [tableData],
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const handleDateFilter = (term, rowData) => {
        return new Date(term).setHours(0, 0, 0, 0) <= new Date(rowData.due_date)
            ? true
            : false;
    };




    const columns = useMemo(
        () => [
            {
                accessorKey: '_id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'FirstName',
                header: 'Nombre',
                size: 140,
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'User_Login',
                header: 'Usuario',
                size: 140,
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                // accessorFn: (row) => new Date(row.Birthday), //convert to Date for sorting and filtering
                // id: 'Birthday',
                // header: 'Cumpleanos',
                // filterFn: 'lessThanOrEqualTo',
                // sortingFn: 'datetime',
                // muiEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                // }),
                // muiTableBodyCellEditTextFieldProps:
                //     ({
                //         required: false,
                //         type: "date",
                //     }),
                // Cell: ({ cell }) => cell.getValue() != '' ? `${cell.getValue().getDate()}/${cell.getValue().getMonth() + 1}/${cell.getValue().getFullYear()}` : `${new Date().getDate() + 1}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`, //render Date as a string
                // Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
                // //Custom Date Picker Filter from @mui/x-date-pickers
                // Filter: ({ column }) => (
                //     <LocalizationProvider dateAdapter={AdapterDayjs}>
                //         <DatePicker
                //             onChange={(newValue) => {
                //                 column.setFilterValue(newValue);
                //             }}
                //             slotProps={{
                //                 textField: {
                //                     helperText: 'Filter Mode: Less Than',
                //                     sx: { minWidth: '120px' },
                //                     variant: 'standard',
                //                 },
                //             }}
                //             value={column.getFilterValue()}
                //         />
                //     </LocalizationProvider>
                // ),
                // accessorKey: 'Birthday',
                // header: 'Birthday',
                // muiEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),                    
                // }),
                accessorFn: (row) => new Date(row.Birthday), //convert to Date for sorting and filtering
                id: 'Birthday',
                header: 'Cumpleanos',
                filterVariant: 'date',
                filterFn: 'lessThan',
                sortingFn: 'datetime',
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                muiTableBodyCellEditTextFieldProps:
                    ({
                        required: false,
                        type: "date",
                    }),
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(), //render Date as a string
                Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
                muiFilterTextFieldProps: {
                sx: {
                    minWidth: '250px',
                },
                }
                // filterVariant: 'date-range',
                // Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
                // //Cell: ({ cell }) => cell.getValue() != '' ? `${cell.getValue().getDate()}/${cell.getValue().getMonth() + 1}/${cell.getValue().getFullYear()}` : `${new Date().getDate() + 1}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`, //render Date as a string
                // muiEditTextFieldProps: ({ cell }) =>({
                //     ...getCommonEditTextFieldProps(cell),
                //     // value: Birthday,
                //     // onChange: setBirthday(moment(cell.getValue()).format("YYYY-MM-DD hh:mm:ss")),
                   
                //  }) 
            },
            {
                accessorKey: 'Email',
                header: 'Correo',
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'email',
                }),
            },
            {
                accessorKey: 'User_password',
                header: 'Contrasena',
                size: 140,
                // Cell: ({ cell }) => cell.getValue().,
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'password',
                }),
            },
            {
                accessorKey: 'UserType_code',
                header: 'Rol',
                size: 5,
                muiEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: usuarioRole.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                            {role.value}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() == '1'
                                    ? theme.palette.success.light
                                    : theme.palette.warning.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue().toLocaleString() == '3'? "Admin" : "Bodega"}
                    </Box>
                ),
            },
            {
                accessorKey: 'ConfirmPassword',
                header: 'ConfirmarContrasena',
                size: 140,
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'password',
                }),
            },
            {
                // accessorFn: (row) => new Date(row.Creation_date), //convert to Date for sorting and filtering
                // id: 'Creation_date',
                accessorKey: 'Creation_date',
                header: 'FechaCreacion',
                size: 80,
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                // filterVariant: 'date',
                // Cell: ({ cell }) => cell.getValue() != '' ? `${cell.getValue().getDate()}/${cell.getValue().getMonth() + 1}/${cell.getValue().getFullYear()}` : `${new Date().getDate() + 1}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`, //render Date as a string
                enableHiding: true,
                enableEditing: false,
            },
            {
                accessorKey: 'Update_date',
                header: 'FechaModificacion',
                size: 80,
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                enableHiding: true,
                enableEditing: false,
            },
            {
                accessorKey: 'IsActive',
                header: 'Activo',
                muiEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: estadoActivo.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                            {state.value}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor:
                                cell.getValue() == 'Activo'
                                    ? theme.palette.success.light
                                    : theme.palette.warning.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue().toLocaleString()}
                    </Box>
                ),
            },
        ],
        [getCommonEditTextFieldProps],
    );
    const validateRequired = (value) => !!value.length;
    const validateEmail = (email) =>
        !!email.length &&
        email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    // const validateAge = (age) => age >= 18 && age <= 50;







    const handleSubmit = (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false || Password != ConfirmPassword || Password.length < 6 && ConfirmPassword.length <= 6) {
            event.preventDefault();
            event.stopPropagation();

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: '(1) Necesitas ingresar todos los valores(Entrada Incorrecta) ! <br/> (2) o Contraseña no coincide ! <br/>(3) O ingrese contraseña con 6 o más dígitos o letras !',
                toast: true,
                position: 'top-end',
                confirmButtonColor: "green",
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })
        }

        else {
            setValidated(true)

            AddDoctor()

            Swal.fire({
                title: 'success',
                icon: 'success',
                toast: true,
                position: 'top-end',
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            }).then((result) => {

                if (result.isConfirmed) {
                    window.location.reload(false);
                }
            })

        }
    };



    //  take all func from LoadDataFromApi component
    const LoadDetailsFromApi = async () => {

        SetUsers(await LoadAllUsers())
        setTableData(await LoadAllUsersTable())
        console.log ("loaddetailsuser: "+tableData);
        SetUsersBlocked(await LoadAllUsersBlocked())
        // SetDoctors(await LoadAllDoctors())
        // SetReviews(await LoadAllReviews())
    }



    // take all func from DeleteDataFromApi component
    const DeleteItemsFromDataApi = async (Id, item) => {

        if (item === "review") {

            await DeleteReview(Id);
            window.location.reload(false);
        }

        if (item === "user") {

            await DeleteUser(Id);
            window.location.reload(false);
        }
    }




    //active all users how was block
    const ActiveUser = async (Id) => {

        await ActiveUserInDataBase(Id);
        window.location.reload(false);
    }



    const AddDoctor = async () => {

        let user = {
            FirstName: FirstName,
            User_Login: Login,
            Birthday: null,
            Email: Email,
            User_password: Password,
            UserType_code: "2",
            ConfirmPassword: ConfirmPassword,
            Creation_date: null,
            Update_date: null,
            Serial_codeHour: null,
            IsActive: "1"
        };

        await AddNewUserRegester(user);

        window.location.reload(false);
    }



    useEffect(() => {

        LoadDetailsFromApi();

        Swal.fire({
            background: 'none',
            width: 400,
            showConfirmButton: false,
            timer: 2100,
            html: '<img src="https://i.postimg.cc/KzypDw9n/admin.png" height="200"></img>'
        })
    }, [])


    if (userData != null) {
        return (

            <>

                <Tabs id="controlled-tab-example" className="mb-3 tabsChiose " >

                    <Tab eventKey="show all table" title="Usuarios" className='AllTable'>
                        <MaterialReactTable
                            displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                        align: 'center',
                                    },
                                    size: 120,
                                },
                            }}
                            columns={columns}
                            data={tableData}
                            enableColumnFilterModes
                            initialState={{ showColumnFilters: true, columnVisibility: { _id: false, User_password: false, ConfirmPassword: false, IsActive: false } }}
                            editDisplayMode="modal" //default
                            enableColumnOrdering
                            enableEditing
                            onEditingRowSave={handleSaveRowEdits}
                            onEditingRowCancel={handleCancelRowEdits}
                            renderRowActions={({ row, table }) => (
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Tooltip arrow placement="left" title="Editar">
                                        <IconButton onClick={() => table.setEditingRow(row)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="right" title="Eliminar">
                                        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                            renderTopToolbarCustomActions={() => (
                                <Button
                                    color="secondary"
                                    onClick={() => setCreateModalOpen(true)}
                                    variant="contained"
                                >
                                    Crear nuevo usuario
                                </Button>
                            )}
                        />
                    </Tab>


                    
                    <Tab eventKey="show all users blocked" title="Usuarios bloqueados" className='AllUsersBlocked'>

                        <Table bordered hover size="sm" variant={(storedTheme === "light") ? "dark" : (storedTheme === "dark") ? "" : ""}>

                            <thead>
                                <tr>
                                    <th style={{ width: "1%", textAlign: "center" }}>#</th>
                                    <th style={{ width: "1%", textAlign: "center" }}>Nombre</th>
                                    <th style={{ width: "1%", textAlign: "center" }}>Usuario</th>
                                    <th style={{ width: "3%", textAlign: "center" }}>Cumpleaños</th>
                                    <th style={{ width: "3%", textAlign: "center" }}>Correo</th>
                                    <th style={{ width: "3%", textAlign: "center" }}>Rol</th>
                                    <td style={{ width: "1%" }}></td>
                                </tr>
                            </thead>

                            {UsersBlocked.map(user =>

                                <tbody key={user._id}>
                                    <tr>
                                        <td style={{ textAlign: "center", fontSize: "13px" }}>{CountUserBlock++}</td>
                                        <td style={{ textAlign: "center", fontSize: "13px" }}>{user.FirstName}</td>
                                        <td style={{ textAlign: "center", fontSize: "13px" }}>{user.User_Login}</td>
                                        <td style={{ textAlign: "center", fontSize: "13px" }}>{user.Birthday}</td>
                                        <td style={{ textAlign: "center", fontSize: "13px" }}>{user.Email}</td>
                                        <td style={{ textAlign: "center", fontSize: "13px" }}>{user.UserType_code}</td>


                                        <td style={{ textAlign: "center", fontSize: "14px" }}>
                                            <Button size="sm" variant="success" onClick={() => ActiveUser(user._id)}>Reactivar</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                    </Tab>
                </Tabs>

            </>
        )
    } else {

        Swal.fire({
            icon: 'warning',
            title: 'Login / Registro',
            html: 'Necesitas iniciar sesión o registrarte',
            toast: true,
            position: 'top-end',
            confirmButtonColor: "green",
            background: `${(storedTheme === "light") ? "#373E44" :
                (storedTheme === "dark") ? "" : ""}`,
            color: `${(storedTheme === "light") ? "#ffffffab" :
                (storedTheme === "dark") ? "" : ""}`,
            buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                (storedTheme === "dark") ? "" : ""}`
        })
        return (
            <NotFoundPage />
        )

    }

}


export default Admin;