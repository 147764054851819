import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import '../css/Contactenos.css'
import date1 from '../Json_date/date.json'
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import { Modal } from 'react-bootstrap';
import SendEmail from '../components/SendEmail'
import { motion as m } from "framer-motion/dist/framer-motion"
import { container, item } from "../styleComponents/StyleAnimation"



//here we show Contactenos the clinic , send message + show data from json file
function Contactenos() {


    let storedTheme = localStorage.getItem("theme");

    // show popup send message
    const [showSendMessage, setShowSendMessage] = useState(false);


    const popUpSendMessage = () => {
        setShowSendMessage(true);
    }



    const closePopUpModelSendMessage = () => {
        setShowSendMessage(false);
    }



    return (

        <m.div variants={container} initial="hidden" animate="show">
            <section className="bannerContactenos">

                <div className={(storedTheme === "light") ? "boxContactenosDark" : (storedTheme === "dark") ? "boxContactenos" : ""}>

                    <h3>Contáctenos</h3>

                    <m.p variants={item}><span className={(storedTheme === "light") ? "boldFirstWordDark" : (storedTheme === "dark") ? "boldFirstWord" : ""}>
                        Ciudad :</span> {date1.Map.country} , {date1.Map.City}
                    </m.p>
                    <m.p variants={item}><span className={(storedTheme === "light") ? "boldFirstWordDark" : (storedTheme === "dark") ? "boldFirstWord" : ""}>
                        Dirección :</span> {date1.Map.addressCity} , {date1.Map.adressNum}
                    </m.p>
                    <m.p variants={item}><span className={(storedTheme === "light") ? "boldFirstWordDark" : (storedTheme === "dark") ? "boldFirstWord" : ""}>
                        Correo :</span> info@grupotrepilastri.com
                    </m.p>
                    <m.p variants={item}><span className={(storedTheme === "light") ? "boldFirstWordDark" : (storedTheme === "dark") ? "boldFirstWord" : ""}>
                        Teléfonos :</span> +502 3061 3619  / +502 2296 8030
                    </m.p>

                    <br />

                    <div className='UserSendEmail'>
                        <Button style={(storedTheme === "light") ? { background: "green" } : (storedTheme === "dark") ? { background: "contained" } : ""}
                            variant="contained"
                            onClick={popUpSendMessage}
                            startIcon={<EmailIcon />}>
                            Click Enviar Mensaje
                        </Button>
                    </div>

                    <>
                        <Modal show={showSendMessage} onHide={closePopUpModelSendMessage} >
                            <SendEmail hideModelSendMessage={closePopUpModelSendMessage} />
                        </Modal>
                    </>

                    <iframe src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=narama&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        width="100%" height="280" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                    </iframe>

                </div>



                <div className={(storedTheme === "light") ? "box contectDark" : (storedTheme === "dark") ? "box contect" : ""}>

                    <div className={(storedTheme === "light") ? "logDark" : (storedTheme === "dark") ? "log" : ""}>
                        <h3>Horario laboral</h3>

                        {date1.hours_work.map((record) => (

                            <m.div variants={item} key={record.id}>
                                <p>{record.day} : {record.time}</p>
                            </m.div>
                        ))}
                        <br />
                        <br />
                        <Card.Img variant="top" src="https://i.postimg.cc/508kqF22/1zx.png" />
                        <br />
                        <br />
                    </div>

                </div>

            </section>
        </m.div>

    )

}

export default Contactenos;