import React, { useState, useEffect } from 'react'
import { Tabs, Tab, Modal, Form, Col, Row, Table } from 'react-bootstrap';
import '../css/profile.css'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import PayService from '../components/PayService'
import { LoadMedicalFileUser, showAllMyReview, LoadMedicalFileUserIsNotActive } from '../Api/LoadDataFromApi'
import { DeleteReview, UpdateDataUserRemoveTurn, ActiveHourInDataBase, UpdateDataUser } from '../Api/DeleteUpdateDataFromApi'
import { alertPopUpIfUserHaveTodayTurn } from './AlertUserHaveTurnToday'
import Button from '@mui/material/Button';
import PaymentIcon from '@mui/icons-material/Payment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CloseIcon from '@mui/icons-material/Close';
import { useQueryOnlyLoadingData, useQueryDataLoadingRefetchAutoData } from "../customHook/customQueryHook"
import NotFoundPage from '../components/NotFoundPage'
import { motion as m } from "framer-motion/dist/framer-motion"



//data_user - take all data user from Page Profile (user)
function User({ data_user }) {


    // popup pay service
    const [showPayService, setShowPayService] = useState(false);
    const handleShowPayService = () => setShowPayService(true);

    let history = useHistory();

    let CountReview = 1;
    let HistoryPayFile = 1;
    let MyReviews = 1;

    let storedTheme = localStorage.getItem("theme");
    let userData = JSON.parse(sessionStorage.getItem("user"));


    // use custom hook , useQuery
    const { isLoading: MyReview, data: myReview, isError: ErrorMyReview } =
        useQueryDataLoadingRefetchAutoData('myReview', null, showAllMyReview, data_user.code);

    const { isLoading: Medical_File, data: medical_File, isError: ErrorMedical_File } =
        useQueryOnlyLoadingData('medical_File', LoadMedicalFileUser, data_user.code);

    const { isLoading: Medical_File_Is_Not_Active, data: medical_File_Is_Not_Active, isError: ErrorMedical_File_Is_Not_Active } =
        useQueryOnlyLoadingData('medical_File_Is_Not_Active', LoadMedicalFileUserIsNotActive, data_user.code);



    const [Login, setLogin] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [Email, setEmail] = useState('');
    const [Birthday, setBirthday] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');




    const CheckValueAndUpdateDataUser = async () => {

        // check value
        if (Password != ConfirmPassword || Password.length < 6 &&
            ConfirmPassword.length <= 6 || Password === '' || ConfirmPassword === '' ||
            Login === '' || FirstName === '' || Email === '' || Birthday === '') {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: '(1) Necesitas ingresar todos los valores(Entrada Incorrecta) ! <br/> (2) o contraseña no coincide ! <br/>(3) O ingresa contraseña con 6 o más digitos o letras !',
                toast: true,
                position: 'top-end',
                confirmButtonColor: "green",
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })
            return;
        }

        // update data
        else {

            let user = {
                FirstName: FirstName,
                User_Login: Login,
                Birthday: Birthday,
                Email: Email,
                User_password: Password,
                UserType_code: "1",
                ConfirmPassword: ConfirmPassword,
                Creation_date: data_user.day,
                Update_date: data_user.hour,
                Serial_codeHour: data_user.codeHour
            }

            await UpdateDataUser(data_user.code, user);

            await Swal.fire({
                position: "center",
                background: "none",
                showConfirmButton: false,
                timer: 1000,
                allowOutsideClick: false,
                html: '<div class="ShowImageWhenRegister"><img src="https://i.postimg.cc/X7RTsp8v/pantsbear-goodjob.gif"> </div>',
            });

            await sessionStorage.clear();
            history.push("/");
            window.location.reload(false);
        }
    }







    //update to NULL day + hour + serial code hour
    const saveDateUserAfterActiveHour = async () => {

        await UpdateDataUserRemoveTurn(data_user.code);

        // clear session storage after delete a hour day and serial code hour
        sessionStorage.clear();
        history.push("/");
        window.location.reload(false);
    }

    //open pop up pay service , save the data to sessionStorage , to use in component PayService
    const OpenPopUpPay = (Serial_code, priceSevice, FirstName) => {

        handleShowPayService();

        let PayDetails = { Serial_code: Serial_code, priceSevice: priceSevice, userName: FirstName }

        sessionStorage.setItem("PayDetails", JSON.stringify(PayDetails))
    }



    // send this function to PayService component
    const hideModelPayService = () => {

        sessionStorage.removeItem("PayDetails");
        window.location.reload(false);
    }



    const alertTodayTurnUser = async () => {

        await alertPopUpIfUserHaveTodayTurn(data_user.day, storedTheme, data_user.hour, data_user.codeHour, data_user.code);
    }




    useEffect(() => {

        alertTodayTurnUser();

        //show use date- when i update user date i show all value in input and choise what i need update
        setFirstName(data_user.name);
        setLogin(data_user.login);
        setEmail(data_user.email);
        setBirthday(data_user.birthday);
        setPassword(data_user.password);
        setConfirmPassword(data_user.confirm_password);
    }, [])




    return (

        <>

            {
                    <m.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        // initial={{ y:"100%" }}
                        // animate={{ y:"0%" }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.75, ease: "easeOut" }}>
                        <div className={(storedTheme === "light") ? "" : (storedTheme === "dark") ? "bg-white" : ""}>

                            <Tabs id="controlled-tab-example" className={(storedTheme === "light") ? "mb-3 tabsChioseDark" : (storedTheme === "dark") ? "mb-3 tabsChiose" : ""} >

                                <Tab eventKey="personal data" title="Datos personales (Actualizar)" className='updateDateUser'>

                                    <Form>

                                        <Row>

                                            <Form.Group as={Col} md="4" className='personalDataPlaceFree'>

                                                <Form.Label className={(storedTheme === "light") ? "colorTextDark" : (storedTheme === "colorText") ? "" : ""}>
                                                    Usuario
                                                </Form.Label>

                                                <Form.Control
                                                    value={Login}
                                                    type="text"
                                                    onChange={(event) => setLogin(event.target.value)}
                                                    style={{ backgroundColor: "white", fontWeight: "600", color: "#00000071" }} />

                                            </Form.Group>


                                            <Form.Group as={Col} md="4" className='personalDataPlaceFree'>

                                                <Form.Label className={(storedTheme === "light") ? "colorTextDark" : (storedTheme === "colorText") ? "" : ""}>
                                                    Nombre
                                                </Form.Label>

                                                <Form.Control
                                                    placeholder="Ingrese correo"
                                                    type="text"
                                                    value={FirstName}
                                                    onChange={(event) => setFirstName(event.target.value)}
                                                    style={{ backgroundColor: "white", fontWeight: "600", color: "#00000071" }} />

                                            </Form.Group>


                                            <Form.Group as={Col} md="4" className='personalDataPlaceFree'>

                                                <Form.Label className={(storedTheme === "light") ? "colorTextDark" : (storedTheme === "colorText") ? "" : ""}>
                                                    Correo
                                                </Form.Label>

                                                <Form.Control
                                                    placeholder="Ingrese correo"
                                                    type="text"
                                                    value={Email}
                                                    onChange={(event) => setEmail(event.target.value)}
                                                    style={{ backgroundColor: "white", fontWeight: "600", color: "#00000071" }} />

                                            </Form.Group>


                                            <Form.Group as={Col} md="4" className='personalDataPlaceFree'>

                                                <Form.Label className={(storedTheme === "light") ? "colorTextDark" : (storedTheme === "colorText") ? "" : ""}>
                                                    Fecha
                                                </Form.Label>

                                                <Form.Control
                                                    placeholder="Ingrese correo"
                                                    type="Date"
                                                    value={Birthday}
                                                    onChange={(event) => setBirthday(event.target.value)}
                                                    style={{ backgroundColor: "white", fontWeight: "600", color: "#00000071" }} />

                                            </Form.Group>


                                            <Form.Group as={Col} md="4" className='personalDataPlaceFree'>

                                                <Form.Label className={(storedTheme === "light") ? "colorTextDark" : (storedTheme === "colorText") ? "" : ""}>
                                                    Contraseña
                                                </Form.Label>

                                                <Form.Control
                                                    placeholder="Ingrese correo"
                                                    type="Password"
                                                    value={Password}
                                                    onChange={(event) => setPassword(event.target.value)}
                                                    style={{ backgroundColor: "white", fontWeight: "600", color: "#00000071" }} />

                                            </Form.Group>


                                            <Form.Group as={Col} md="4" className='personalDataPlaceFree'>

                                                <Form.Label className={(storedTheme === "light") ? "colorTextDark" : (storedTheme === "colorText") ? "" : ""}>
                                                    Confirme contraseña
                                                </Form.Label>

                                                <Form.Control
                                                    placeholder="Confirme contraseña"
                                                    type="Password"
                                                    value={ConfirmPassword}
                                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                                    style={{ backgroundColor: "white", fontWeight: "600", color: "#00000071" }} />

                                            </Form.Group>
                                        </Row>

                                        <div className='enterUpdate'>
                                            <Button style={{ fontSize: "13px", background: "green", color: "white" }} variant="contained"
                                                onClick={CheckValueAndUpdateDataUser}
                                                startIcon={<UpgradeIcon />}>
                                                Actualizar
                                            </Button>
                                        </div>
                                    </Form>

                                </Tab>

                            </Tabs>

                        </div>
                    </m.div>
            }
        </>
    )

}


export default User;




// USE State

// const [MyReview, SetMyReview] = useState([]);
// const [medical_File, SetMedical_File] = useState([]);
// const [medical_File_Is_Not_Active, SetMedical_File_Is_Not_Active] = useState([]);

// load data user from LoadDataFromApi component
// const LoadDataUserFromApi = async () => {
//      SetMedical_File(await LoadMedicalFileUser(data_user.code))
//      SetMyReview(await showAllMyReview(data_user.code))
//      SetMedical_File_Is_Not_Active(await LoadMedicalFileUserIsNotActive(data_user.code))
// }