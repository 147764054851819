// connect to local host Node js server mongoDB
export const Config = {

    API: {
        BASE: process.env.REACT_APP_API
    }
}




// here for test connect nodeJs server

// export const Config = {


//     API: {
//         BASE: "http://localhost:5000",
//     }

// }