import React, { useState } from 'react'
import { API } from '../Api/API';
import { Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import '../css/forgetPassword.css'
import { ForgetPasswordUpdate } from '../Api/DeleteUpdateDataFromApi'
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';



//here component forget Paswword use in sign In component
function ForgetPaswword() {


    const [showNewNewPassword, setShowNewPassword] = useState(false);
    const handleShowNewPassword = () => setShowNewPassword(true);

    const [Email, setEmail] = useState('');

    const [User_password, setUser_password] = useState('');
    const [Confirm_password, setConfirm_password] = useState('');


    let userForget = JSON.parse(sessionStorage.getItem("userForgetPassword"));

    let storedTheme = localStorage.getItem("theme");



    //here we search if we have this email in data bse , if have we send the data use from data base to sessionStorage
    const searchEmailFromDataBase = async () => {

        if (Email < 1) {
            Swal.fire({
                text: 'Porfavor ingresa tu correo',
                icon: 'warning',
                toast: true,
                position: 'top-end',
                confirmButtonColor: "green",
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })
        }

        else {

            try {

                let user =
                {
                    Email: Email
                };

                let res = await fetch(API.USERS.FORGET, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(user)
                });

                let data = await res.json();

                Swal.fire({
                    title: `${data.FirstName} Encontrado. Ingresa nueva contraseña`,
                    icon: 'info',
                    toast: true,
                    position: 'top-end',
                    confirmButtonColor: "green",
                    background: `${(storedTheme === "light") ? "#373E44" :
                        (storedTheme === "dark") ? "" : ""}`,
                    color: `${(storedTheme === "light") ? "#ffffffab" :
                        (storedTheme === "dark") ? "" : ""}`,
                    buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                        (storedTheme === "dark") ? "" : ""}`
                }).then((result) => {

                    if (result.isConfirmed) {

                        sessionStorage.setItem("userForgetPassword", JSON.stringify(data));
                        handleShowNewPassword() // show pop up change password
                    }
                })

            } catch (error) {
                console.log(error);
            }
        }
    }



    // check value input a new password
    const checkValueInput = () => {

        if (User_password === '' || Confirm_password === '') {

            Swal.fire({
                text: 'Por favor ingresa nueva contraseña!',
                icon: 'error',
                toast: true,
                position: 'top-end',
                confirmButtonColor: "green",
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })

            return;
        }


        if (User_password === Confirm_password) {

            ForgetPassword();
        }


        else {

            Swal.fire({
                text: 'Contraseña no coincide!',
                icon: 'error',
                toast: true,
                position: 'top-end',
                confirmButtonColor: "green",
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })
        }

    }



    //here update to new password 
    const ForgetPassword = async () => {

        let user = {
            User_password: User_password,
            ConfirmPassword: Confirm_password
        }

        await ForgetPasswordUpdate(userForget._id, user);

        await Swal.fire({
            icon: 'success',
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            background: `${(storedTheme === "light") ? "#373E44" :
                (storedTheme === "dark") ? "" : ""}`,
            color: `${(storedTheme === "light") ? "#ffffffab" :
                (storedTheme === "dark") ? "" : ""}`,
            buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                (storedTheme === "dark") ? "" : ""}`
        })

        sessionStorage.clear('userForgetPassword');
       window.location.reload(false);
    }




    //if you click not save new password
    const closeForgetPassword = () => {

        Swal.fire({
            title: 'Seguro que no deseas cambiar contraseña?',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'si',
            denyButtonText: `no`,
            toast: true,
            position: 'top-end',
            confirmButtonColor: "green",
            background: `${(storedTheme === "light") ? "#373E44" :
                (storedTheme === "dark") ? "" : ""}`,
            color: `${(storedTheme === "light") ? "#ffffffab" :
                (storedTheme === "dark") ? "" : ""}`,
            buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                (storedTheme === "dark") ? "" : ""}`
        }).then((result) => {

            if (result.isConfirmed) {

                sessionStorage.clear('userForgetPassword');
               window.location.reload(false);
            }
        })
    }



    return (

        <>
            <div className='enterEmail'>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="email"
                        placeholder="Ingresa tu correo"
                        value={Email}
                        onChange={(event) => setEmail(event.target.value)}
                        autoFocus
                    />

                    <div className='startChangePassword'>
                        <Button style={{ fontSize: "13px", color: "white" }} variant="contained"
                            onClick={searchEmailFromDataBase} startIcon={<DoneIcon />}>
                            Aceptar
                        </Button>

                        <Button style={{ fontSize: "13px", color: "white", background: "gray" }} variant="contained"
                            onClick={closeForgetPassword} startIcon={<CloseIcon />}>
                            Cerrar
                        </Button>
                    </div>

                </Form.Group>
            </div>



            <div className='inputChangePasswort'>
                <Modal show={showNewNewPassword} style={{ background: "rgba(0, 0, 0, 0.9)" }}>

                    <Modal.Header>
                        <Modal.Title><h1>Ingresa nueva contraseña :</h1></Modal.Title>
                    </Modal.Header>


                    <Form.Group className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder="Ingresa nueva contraseña"
                            value={User_password}
                            onChange={(event) => setUser_password(event.target.value)}
                            autoFocus
                        />
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder="Confirma contraseña"
                            value={Confirm_password}
                            onChange={(event) => setConfirm_password(event.target.value)}
                        />
                    </Form.Group>


                    <div className='startChangePassword'>

                        <Button style={{ fontSize: "13px", color: "white" }} variant="contained"
                            onClick={checkValueInput} startIcon={<SaveIcon />}>
                            Guardar cambios
                        </Button>

                        <Button style={{ fontSize: "13px", color: "white", background: "gray" }} variant="contained"
                            onClick={closeForgetPassword} startIcon={<CloseIcon />}>
                            Cerrar
                        </Button>

                    </div>
                </Modal>
            </div>
        </>

    )
}


export default ForgetPaswword;