import React, { useState } from 'react'
import { Form, Col, Row } from 'react-bootstrap';
import '../css/register.css'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
import { AddNewUserRegester } from '../Api/ConnectOrAddFromApi'
import Button from '@mui/material/Button';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { motion as m } from "framer-motion/dist/framer-motion"
import moment from 'moment';



// here Register Page
function Register() {

    const history = useHistory()

    // input date Birthday , and show default date when input your date
    // let d = new Date();
    // let DatePublished = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    let date = moment(new Date()).format('DD/MM/YYYY')
    const [Birthday, setBirthday] = useState(date);
    
    // input value when register
    const [User_Login, setLogin] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');

    let storedTheme = localStorage.getItem("theme");



    const checkInputValueRegister = async () => {

        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (Password != ConfirmPassword ||
            Password.length < 6 && ConfirmPassword.length <= 6 ||
            User_Login === '' ||
            FirstName === '' ||
            Email === '' ||
            Password === '' ||
            ConfirmPassword === '' ||
            mailFormat.test(Email) === false) {

            Swal.fire({
                icon: 'error',
                title: 'Please Check',
                toast: true,
                confirmButtonColor: "green",
                html: `(1) Ingrese todos los valores(Entrada Incorrecta) ! <br/>
                 (2) Password NOT Equals ! <br/>
                 (3) Password with 6 or more digits or letters ! <br/>
                 (4) Check if your Email was Good`,
                position: 'top-end',
                background: `${(storedTheme === "light") ? "#373E44" :
                    (storedTheme === "dark") ? "" : ""}`,
                color: `${(storedTheme === "light") ? "#ffffffab" :
                    (storedTheme === "dark") ? "" : ""}`,
                buttonColor: `${(storedTheme === "light") ? "#E96E00" :
                    (storedTheme === "dark") ? "" : ""}`
            })
            return;
        }


        else {

            await registerUser();
            history.push("/")
        }

    }



    const registerUser = async () => {
        await Swal.fire({
            position: "center",
            background: "none",
            showConfirmButton: true,
            timer: 4000,
            allowOutsideClick: false,
            html: '<div class="ShowImageWhenRegister"><img src="https://i.postimg.cc/MZP7Xzk6/cute-penguin.gif"> </div>',
        });

        let user = {
            FirstName,
            User_Login,
            Birthday,
            Email,
            User_password: Password,
            UserType_code: "1",
            ConfirmPassword,
            Creation_date: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
            Update_date: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
            Serial_codeHour: null,
            IsActive: "1"
        };

        await AddNewUserRegester(user);
    }



    return (

        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            // initial={{ y: "100%" }}
            // animate={{ y: "0%" }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
        >
            <section className="banner1">

                <div className={(storedTheme === "light") ? "box1 contect1Dark" : (storedTheme === "dark") ? "box1 contect1" : ""}>

                    <div className={(storedTheme === "light") ? "log1Dark" : (storedTheme === "dark") ? "log1" : ""}>

                        <Form
                            style={(storedTheme === "light") ? { textAlign: "center", alignItems: "center", color: "white" } :
                                (storedTheme === "dark") ? { textAlign: "center", alignItems: "center", color: "#4b4b4b" } : ""}>
                            <Row>
                                <Form.Group as={Col} md="12">
                                    <div>
                                        <p>   </p>
                                        <p>   </p>
                                        <p>   </p>
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Control
                                        style={(storedTheme === "light") ? { fontSize: "14px", textAlign: "center" } :
                                            (storedTheme === "dark") ? { background: "rgba(0, 0, 0, 0.1)", fontSize: "14px", textAlign: "center" } : ""}
                                        placeholder='Ingrese Usuario'
                                        value={User_Login}
                                        onChange={(event) => setLogin(event.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Control
                                        style={(storedTheme === "light") ? { fontSize: "14px", textAlign: "center" } :
                                            (storedTheme === "dark") ? { background: "rgba(0, 0, 0, 0.1)", fontSize: "14px", textAlign: "center" } : ""} type="text"
                                        placeholder='Ingrese Nombre'
                                        value={FirstName}
                                        onChange={(event) => setFirstName(event.target.value)}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Control
                                        style={(storedTheme === "light") ? { fontSize: "14px", textAlign: "center" } :
                                            (storedTheme === "dark") ? { background: "rgba(0, 0, 0, 0.1)", fontSize: "14px", textAlign: "center" } : ""} type="email"
                                        placeholder='Ingrese Correo'
                                        value={Email}
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Control
                                        style={(storedTheme === "light") ? { fontSize: "14px", textAlign: "center" } :
                                            (storedTheme === "dark") ? { background: "rgba(0, 0, 0, 0.1)", fontSize: "14px", textAlign: "center" } : ""} type="password"
                                        placeholder='Ingrese contraseña'
                                        value={Password}
                                        onChange={(event) => setPassword(event.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                    <Form.Control
                                        style={(storedTheme === "light") ? { fontSize: "14px", textAlign: "center" } :
                                            (storedTheme === "dark") ? { background: "rgba(0, 0, 0, 0.1)", fontSize: "14px", textAlign: "center" } : ""} type="password"
                                        placeholder='Ingrese contraseña de confirmación'
                                        value={ConfirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                    />
                                </Form.Group>
                            </Row>


                            <Row>
                                <Form.Group as={Col} md="12">

                                    <div className='titleBirthdayDate'>
                                        <p>Ingrese fecha de cumpleaños:</p>
                                    </div>

                                    <Form.Control
                                        style={(storedTheme === "light") ? { fontSize: "14px", textAlign: "center" } :
                                            (storedTheme === "dark") ? { background: "rgba(0, 0, 0, 0.1)", fontSize: "14px", textAlign: "center" } : ""} type="date"
                                        value={Birthday}
                                        onChange={(event) => setBirthday(event.target.value)}
                                    />
                                </Form.Group>
                            </Row>


                            <Button style={{ fontSize: "13px", background: "green", color: "white" }} variant="contained"
                                onClick={checkInputValueRegister}
                                startIcon={<HowToRegIcon />}>
                                Registrar
                            </Button>
                        </Form>
                    </div>
                </div>

                <div className="box1 image1"></div>
            </section>
        </m.div>
    )

}


export default Register;