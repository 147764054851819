import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from 'react'
import Register from './Pages/Register'
import Home from './Pages/Home'
import Menu from './components/menu'
import Admin from './components/Admin'
import Fotter from './components/fotter'
import Contactenos from './Pages/Contactenos'
import Servicios from './Pages/Servicios'
import Service from './Pages/service'
import ShippingPrincipal from './Pages/ShippingPrincipal'

import profile from './Pages/profile'
import About from './Pages/About'
// import DarkMode from "./components/DarkMode"
import NotFoundPage from "./components/NotFoundPage";
import User from './components/User'


function App() {

  return (

    <BrowserRouter>

      {/* Dark mode or light
      <DarkMode /> */}

      <Menu />

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/Admin" exact component={Admin} />
        <Route path="/About" exact component={About} />
        <Route path="/Register" exact component={Register} />
        <Route path="/Contactenos" exact component={Contactenos} />
        <Route path="/Servicios" exact component={Servicios} />
        <Route path="/Service" exact component={Service} />
        <Route path="/Shipping" exact component={ShippingPrincipal} />
        <Route path="/Profile/:id" exact component={profile} />
        <Route path="/User" exact component={User} />        

        {/* active page not found */}
        <Route path="*" exact component={NotFoundPage} />
      </Switch>

      <Fotter />

    </BrowserRouter>
  );
}

export default App;